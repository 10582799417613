import '../assets/style/service.css'
export default function Service(content,rows){

    return(
        <section id="services" className="services">

            <div className="container">

                <header className="section-header">
                    <h2>{content.serviceTitle}</h2>
                    <p>{content.serviceSubtitle}</p>
                </header>

                <div className="row gy-4">
                    {rows.map(row=>{
                    return(
                        <div key={row.id} className="col-lg-4 col-md-6">
                        <div className={row.serviceDivClassname}>
                            {row.serviceIcon}
                        <h3>{row.serviceTitle}</h3>
                        </div>
                        </div>
                        )})}



                </div>

            </div>

        </section>
    )

}
