import React from "react";
// import {Link} from "react-router-dom";
import '../assets/style/hero.css'
// import {Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";

export function HeroForm(hero,setHero){
    // const handleChangeHero=(event =>{
    //     const key = event.target.name
    //     const value = event.target.value
    //     setHero({ ...hero, [key]: value })
    // })
    return (
        <>
            {/*<FormGroup>*/}
            {/*    <FormLabel>Titre</FormLabel>*/}
            {/*    <FormControl type="text" name="title" value={hero.title} onChange={handleChangeHero} required/>*/}
            {/*</FormGroup>*/}
            {/*<FormGroup>*/}
            {/*    <FormLabel>Image</FormLabel>*/}
            {/*    <FormControl type="text" name="backgroundurl" value={hero.backgroundurl} onChange={handleChangeHero} required/>*/}
            {/*</FormGroup>*/}
            {/*<FormGroup>*/}
            {/*    <FormLabel>Sous-titre</FormLabel>*/}
            {/*    <FormControl type="text" name="subtitle" value={hero.subtitle} onChange={handleChangeHero} required/>*/}
            {/*</FormGroup>*/}
            {/*<FormGroup>*/}
            {/*    <FormLabel>Lien bouton 1</FormLabel>*/}
            {/*    <FormControl type="text" name="btn1url" value={hero.btn1url} onChange={handleChangeHero} required/>*/}
            {/*</FormGroup>*/}
            {/*<FormGroup>*/}
            {/*    <FormLabel>Message bouton 1</FormLabel>*/}
            {/*    <FormControl type="text" name="btn1value" value={hero.btn1value} onChange={handleChangeHero} required/>*/}
            {/*</FormGroup>*/}
            {/*<FormGroup>*/}
            {/*    <FormLabel>Lien bouton 2</FormLabel>*/}
            {/*    <FormControl type="text" name="btn2url" value={hero.btn2url} onChange={handleChangeHero} required/>*/}
            {/*</FormGroup>*/}
            {/*<FormGroup>*/}
            {/*    <FormLabel>Message bouton 2</FormLabel>*/}
            {/*    <FormControl type="text" name="btn2value" value={hero.btn2value} onChange={handleChangeHero} required/>*/}
            {/*</FormGroup>*/}
    </>
)
}
export function setHeroModal(content,setShow,setDataModal){
    const datamodal=<>
        <b>Bachelor Développement Web et Mobile : Prés requis</b><br/>
        Avoir obtenu au minimum un diplôme BAC+2 ou plus dans le domaine de l’informatique :
        DUT, BTS et autres Bac+2 à dominante informatique ou technologique. <br/>
        DUT : Info, GEII, MP, GMP, R&T, MMI, GIM, STID, SQLIO <br/>
        BTS : SIO, SN, CIEL<br/>
        AFPA<br/>
        Diplômes universitaires (L2) à dominantes informatique.<br/>
        <br/>
        <b>Expert en Développement Logiciel : Prés requis</b><br/>
        Avoir obtenu au minimum un diplôme BAC+3 ou plus dans le domaine de l’informatique :<br/>
        BUT, Licence, Bachelor<br/><br/>

        <b>Frais d’inscription et de scolarité</b><br/>
        Frais de scolarité : <b>0 €</b>. La scolarité étant en alternance dans une entreprise, les frais sont pris en charge par l’OPCO auquel adhère l’entreprise ou l’entreprise elle-même (tout ou partie).
        <br/><br/>
        Frais d’inscription/de dossier : <b>0 €</b><br/><br/>

        <b>Modalités et délais d’accès</b><br/>
        Ouverture des inscriptions rentrée 2024 : <b>inscriptions ouvertes</b>
        <br/>
        <a href={content.heroBtnHref} target="_blank" rel="noreferrer"
           className="btn-get-started d-inline-flex align-items-center justify-content-center align-self-center" style={{fontSize:"2em"}}>{content.heroBtnContent}<i
            className="bi bi-arrow-right"/></a><br/>
        Une fois votre inscription en ligne terminée votre dossier d’inscription sera vérifié et validé par nos services dans un délais de 7 jours ouvrés. Après cette phase de validation vous recevrez notre réponse acceptation, refus ou dossier incomplet par courrier électronique.
        <br/><br/>
        Si vous ne pouvez pas vous inscrire en ligne contactez-nous.
        <br/><br/>
        Si votre dossier d’inscription est validé et accepté les étapes sont les suivantes:
        <br/><br/><ul>
        <li>Nous vous contactons pour prendre rendez-vous avec vous pour un entretien d’inscription et une évaluation technique, sous 7 jours ouvrés.</li>
        <li>L’entretien d’inscription et l’évaluation technique se passent dans les locaux de l’esimed et durent en moyenne 2h.</li>
        <li>Votre dossier d’inscription ainsi que votre évaluation technique sont étudiés sous 2 jours ouvrés.</li>
        <li>Vous recevez une réponse d’acceptation ou de refus par courrier postal ou courrier électronique.</li></ul></>
    setDataModal({title:<b>Dossier d’inscription</b>,body:datamodal})
    setShow(true)
}
export function Hero(content,rows,setShow,setDataModal) {
    // let backgroundimage="linear-gradient(rgba(40,58,90, 0.5), rgba(40,58,90, 0.5)),url(\""+hero.backgroundurl+"\")"
    // let colortitle=hero.colortitle
    // let colorsubtitle=hero.colorsubtitle
    // let fontsizetitle=hero.fontsizetitle
    // let fontsizesubtitle=hero.fontsizesubtitle
    // console.log(backgroundimage)


    return (
        <section id="hero" className="hero">
            <div className="container position-relative">
                <div className="row gy-5" data-aos="fade-in">
                    <div
                        className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
                        <h2>{content.heroTitle}</h2>
                        <p>{content.heroSubtitle}</p>
                        <div className="d-flex justify-content-center justify-content-lg-start align-content-center">
                            <div className="row d-flex justify-content-center align-content-center" style={{padding:"20px"}}>
                                <div className="col-lg-4 d-flex justify-content-center" style={{margin:"auto"}}>
                                    <p>En partenariat avec :</p>
                                </div>
                                <div className="col-lg-8 d-flex justify-content-start">
                                    <a href="https://www.3il-ingenieurs.fr/" target="_blank" rel="noreferrer"><img src={"/img/logo_3il-small.png"} width="50%" alt=""/></a>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center justify-content-lg-start">
                            <button onClick={() => setHeroModal(content,setShow,setDataModal)}
                               className="btn-get-started d-inline-flex align-items-center justify-content-center align-self-center">{content.heroBtnContent}<i
                                className="bi bi-arrow-right"/></button>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 testanim move-horizontal">
                        <img src={content.heroImgSrc} className="img-fluid" alt={content.heroImgAlt} data-aos="zoom-out"
                             data-aos-delay="100"/>
                    </div>
                </div>
            </div>

            <div className="icon-boxes position-relative">
                <div className="container position-relative">
                    <div className="row gy-4 mt-5">
                        {
                            rows.map(row => {
                                return (
                                    <div key={row.id} className="col-xl-3 col-md-6" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <div className="icon-box">
                                            <div className="icon"><i className={row.IclassName}></i></div>
                                            <h4 className="title"><a href="/"
                                                                     className="stretched-link">{row.content} </a></h4>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
