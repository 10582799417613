import React, {useEffect, useState} from 'react';
import '../assets/style/header.css';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import {Button, NavDropdown} from "react-bootstrap";
import collapse from "bootstrap/js/src/collapse";

export function setHeaderModal(content,setShow,setDataModal){
    const datamodal=<>
        <b>Bachelor Développement Web et Mobile : Prés requis</b><br/>
        Avoir obtenu au minimum un diplôme BAC+2 ou plus dans le domaine de l’informatique :
        DUT, BTS et autres Bac+2 à dominante informatique ou technologique. <br/>
        DUT : Info, GEII, MP, GMP, R&T, MMI, GIM, STID, SQLIO <br/>
        BTS : SIO, SN, CIEL<br/>
        AFPA<br/>
        Diplômes universitaires (L2) à dominantes informatique.<br/>
        <br/>
        <b>Expert en Développement Logiciel : Prés requis</b><br/>
        Avoir obtenu au minimum un diplôme BAC+3 ou plus dans le domaine de l’informatique :<br/>
        BUT, Licence, Bachelor<br/><br/>

        <b>Frais d’inscription et de scolarité</b><br/>
        Frais de scolarité : <b>0 €</b>. La scolarité étant en alternance dans une entreprise, les frais sont pris en charge par l’OPCO auquel adhère l’entreprise ou l’entreprise elle-même (tout ou partie).
        <br/><br/>
        Frais d’inscription/de dossier : <b>0 €</b><br/><br/>

        <b>Modalités et délais d’accès</b><br/>
        Ouverture des inscriptions rentrée 2024 : <b>inscriptions ouvertes</b>
        <br/>
        <a href={content.heroBtnHref} target="_blank" rel="noreferrer"
           className="btn-get-started d-inline-flex align-items-center justify-content-center align-self-center" style={{fontSize:"2em"}}>{content.heroBtnContent}<i
            className="bi bi-arrow-right"/></a><br/>
        Une fois votre inscription en ligne terminée votre dossier d’inscription sera vérifié et validé par nos services dans un délais de 7 jours ouvrés. Après cette phase de validation vous recevrez notre réponse acceptation, refus ou dossier incomplet par courrier électronique.
        <br/><br/>
        Si vous ne pouvez pas vous inscrire en ligne contactez-nous.
        <br/><br/>
        Si votre dossier d’inscription est validé et accepté les étapes sont les suivantes:
        <br/><br/><ul>
        <li>Nous vous contactons pour prendre rendez-vous avec vous pour un entretien d’inscription et une évaluation technique, sous 7 jours ouvrés.</li>
        <li>L’entretien d’inscription et l’évaluation technique se passent dans les locaux de l’esimed et durent en moyenne 2h.</li>
        <li>Votre dossier d’inscription ainsi que votre évaluation technique sont étudiés sous 2 jours ouvrés.</li>
        <li>Vous recevez une réponse d’acceptation ou de refus par courrier postal ou courrier électronique.</li></ul></>
    setDataModal({title:<b>Dossier d’inscription</b>,body:datamodal})
    setShow(true)
}
export default function Header(content,rows,setShow,setDataModal){
    const [navbar, setNavbar]=useState(false)
    const [brand,setBrand]=useState(content.headerBrand)
    const changeBackground = () => {
        if (window.scrollY >= 60 && !navbar) {
            setNavbar(true)
            setBrand(<img alt="" src="/img/Logo_esimed_maison_blanc.png" className="d-inline-block align-top" height="50"/>)
        }
        if (window.scrollY < 60 && navbar){
            setNavbar(false)
            setBrand(content.headerBrand)
        }
    }
    useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    })

    return (
            <Navbar collapseOnSelect  id="header" sticky="top" expand="lg"
                className={navbar ?"navbar active" : "navbar"} style={{width:"100%"}}>
                <div className="container" >
                    <Navbar.Brand href="/" className="logo d-flex align-items-center">
                        {brand}

                    </Navbar.Brand>
                    <Navbar.Toggle/>
                    <Navbar.Collapse  className="justify-content-end align-items-center">
                        <Nav >
                            {
                                rows.map(row => {
                                    return (<Nav.Link key={row.id} className='li' href={row.href} >{row.content}</Nav.Link>)
                                })
                            }
                            <NavDropdown className='li nav-link' title="Extranet" >
                                <NavDropdown.Item href="https://extranet.esimed.fr/etudiant/" target="_blank" rel="noreferrer">Notes & Emploi du Temps</NavDropdown.Item>
                                <NavDropdown.Item href="https://extranet.esimed.fr/suivi/" target="_blank" rel="noreferrer">Suivi des Anciens</NavDropdown.Item>
                                <NavDropdown.Item href="https://extranet.esimed.fr/tuteurs/" target="_blank" rel="noreferrer">Carnet de liaison</NavDropdown.Item>
                                <NavDropdown.Item href="https://extranet.esimed.fr/erp/" target="_blank" rel="noreferrer">Erp</NavDropdown.Item>
                            </NavDropdown>
                            <Button onClick={() => setHeaderModal(content,setShow,setDataModal)}
                               className="header-btn-get-started d-inline-flex align-items-center justify-content-center align-self-center">Inscription<i
                                className="bi bi-arrow-right"/></Button>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
    );
}
