import React from "react";
import "../assets/style/handi.css"
export function Handi(content) {
    return (
        <section id="handi" className="handi">

            <div className="container" >
                <div className="row gx-0 justify-content-center">
                    <div className="col-lg-4 d-flex align-items-center justify-content-end">
                        <img src="/img/handi.jpg" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center">
                        <div className="content">
                            <h3>{content.handiTitle}</h3>
                            <p>
                                {content.handiContent}
                            </p>
                        </div>
                    </div>


                </div>
            </div>

        </section>

    );
}