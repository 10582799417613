import React from "react";
import "../assets/style/cta.css"
import {setAboutModal} from "./about";
import {Button} from "react-bootstrap";
export function CtaForm(cta,setCta){}

const prog1title=<>Bachelor Développement Web et Mobile</>
const prog1body = <>
    <b>Modalités</b><br/>
    <b>Durée :</b> 1 an<br/>
    <b>Titre RNCP :</b> Concepteur développeur d'applications<br/>
    <b><a href="https://www.francecompetences.fr/recherche/rncp/37873/#ancre4" target="_blank" rel="noreferrer"> RNCP
                    Niveau 6</a></b><br/>
    <b>Localisation :</b> Marseille Technopôle de Château Gombert<br/>

    <b>Forme :</b> en alternance en contrat d’apprentissage ou de professionnalisation<br/>

    <b>Niveau d’admission :</b> BAC+2 minimum à dominante informatique (BTS, DUT, Afpa, classes préparatoires, L2,
    …)<br/>

    <b>Conditions d’admissions :</b> sur dossier<br/>

    <b>Inscription :</b> en ligne<br/>

    <b>Frais de scolarité :</b> 0 €. La scolarité étant en alternance dans une entreprise, les frais sont pris en charge
    par l’OPCO auquel adhère l’entreprise ou l’entreprise elle-même (tout ou partie).<br/>

    <b> Frais d’inscription/de dossier :</b> 0 €<br/>

    <b>Évaluations :</b> contrôle continu sous forme de mini projets (réalisation d’applications à partir de cahier des
    charges).<br/>

    <b>Accessibilité :</b> Nos locaux sont accessibles aux personnes handicapées, en rez-de-chaussée et sans
    escaliers.<br/>

    <b>Programme</b>
    <ul>
        <li><b>Développement logiciel</b><ul>
        <li>Algorithmique, qualité du code, gestion de la mémoire, architecture d’applications</li>

        <li>Développement orienté objet et fonctionnel</li>

        <li>Bases de données requêtes et administration</li>

        <li>Modélisation UML</li>
        </ul></li>
        <li><b>Développement Web et Mobile</b><ul>
        <li>Développement Frontend en JavaScript
            (Langage ECMA Script, frontend web responsive, utilisation de web services REST, sécurité, ergonomie,
            accessibilité)
        </li>

        <li>Développement Backend avec NodeJS
            (Framework Express, architecture REST, sécurité JWT)
        </li>

        <li>Développement mobile avec Android
            (Langage Kotlin, API Android, architecture, accès services REST, ergonomie, accessibilité)
        </li>
        </ul></li>
        <li><b>Systèmes et Réseaux</b><ul>
            <li>Utilisation de Linux et scripts (Commandes, filtres, scripts Shell)
        </li>

        <li>Déploiement backend et frontend sur le Cloud Amazon Web Service dans le cadre de la mise en situation (Mise
            en place
            réseau local virtuel avec instances frontend, backend et SGBD, sécurisation avec firewalls, installations et
            configuration des services et applications web)
        </li>
        </ul></li>
        <li><b>SHES </b><ul><li>Anglais</li><li>Communication écrite et orale</li><li>Veille technologique</li></ul></li>

        <li><b>Mises en situation</b><ul> <li>Planification et suivi du projet</li><li>Développement</li><li>Déploiement</li><li>Tests</li><li>Utilisation de
            git</li>
        </ul></li>
        <li><b>Mission en entreprise (soutenance)</b></li>
    </ul>
    <b>Compétences visées</b>
    <ul>
        <li>La conception et la gestion solutions applicatives ou web nécessitent du développeur qu’il soit capable
            d’analyser
            les besoins et de proposer des solutions adaptées. Le métier consiste, à partir d’un cahier des charges,
            de
            concevoir une solution web, web mobile ou logicielle dans un environnement spécifique (sécurité
            informatique,
            infrastructure existante…).
        </li></ul>
        <b>Emploi et débouchés</b>
        <ul>
        <li>Entreprise de services numériques (ESN ou ex-SSII) réalisant des prestations de développement
            d’applications, en
            régie ou au forfait.
        </li>
        <li>Structure utilisatrice, de type entreprise du secteur privé ou public, possédant un service dédié
            aux études et aux développements informatiques.
        </li>
        <li>Activité d’informaticien d’études indépendant.</li>

        <li>Concepteur développeur, concepteur d’applications informatiques.</li>
        <li> Développeur d’applications, développeur informatique.</li>
        <li>Développeur web, développeur back-end.</li>
        <li>Développeur d’applications mobiles, développeur web mobile.</li>
        <li>Ingénieur d’études et développement.</li>
    </ul>

    <b>Objectifs</b><br/>
    Le développeur web et mobile doit savoir concevoir et développer
    des composants d’interface utilisateur en intégrant
    les recommandations de sécurité, concevoir et développer la
    persistance des données en intégrant les recommandations
    de sécurité et concevoir et développer une application
    multicouche répartie en intégrant les recommandations de
    sécurité.
</>;
const prog2title=<>Expert en développement logiciel</>
const prog2body = <>
    <b>Modalités</b><br/>
    <b>Durée :</b> 2 ans<br/>
    <b>Titre RNCP :</b> Manager de solutions digitales et data<br/>
    <b><a href="https://www.francecompetences.fr/recherche/rncp/34408/" target="_blank" rel="noreferrer"> RNCP
                    Niveau 7</a></b><br/>

    <b>Localisation :</b> Marseille Technopôle de Château Gombert<br/>

    <b>Forme :</b> en alternance en contrat d’apprentissage ou de professionnalisation<br/>

    <b>Niveau d’admission :</b> BAC+3 minimum à dominante informatique (Bachelor, L3, BUT, …). Accès privilégiés pour
    les diplômés Bachelor Développement Web et Mobile.<br/>

    <b>Conditions d’admissions :</b> sur dossier<br/>

    <b>Inscription :</b> en ligne<br/>

    <b>Frais de scolarité :</b> 0 €. La scolarité étant en alternance dans une entreprise, les frais sont pris en charge
    par l’OPCO auquel adhère l’entreprise ou l’entreprise elle-même (tout ou partie).<br/>

    <b> Frais d’inscription/de dossier :</b> 0 €<br/>

    <b>Évaluations :</b> contrôle continu sous forme de mini projets (réalisation d’applications à partit de cahier des
    charges) et de mises en situation (planification, suivi et déploiements de projets).<br/>

    <b>Accessibilité :</b> Nos locaux sont accessibles aux personnes handicapées, en rez-de-chaussée et sans
    escaliers.<br/>

    <b>Programme</b>
    <ul>
        <li><b>Ingénierie de développement</b><ul>
        <li>Développement d’applications web MVC et API REST (Framework Symfony)</li>
        <li>Développement d’applications d’entreprises et distribuées (Microsoft .Net ou Java Spring)</li>
        <li>Architecture d’applications</li>
        <li>Développement de front end web, mobile et responsive (Frameworks React ou Angular )</li>
        <li>Développement fonctionnel et par composants (Frameworks React ou Angular)</li>
        <li>Développement de Jeux Vidéos (Godot ou Unreal Engine)</li>
        </ul></li>
        <li><b>Ingénierie du service</b><ul>
        <li>Mise en production et déploiement (projets et mises en situations)</li>
        <li>Gestion du support (projets et mises en situations)</li>
        <li>Industrialisation du développement (scripting shell Linux et PowerShell, utilisation de git en équipe, tests
            logiciels, déploiements continus)
        </li>
        <li>Gestion de d’architectures Cloud (Amazon Web Service)</li>
        <li>Administration de bases de données (Oracle et MySQL)</li>
        </ul></li>
        <li><b>Ingénierie de projet</b><ul>
        <li>Rédaction de cahier des charges et de spécifications fonctionnelles</li>
        <li>Analyse de cahier des charges</li>
        <li>Gestion des exigences et des fonctionnalités</li>
        <li>Planification de projet</li>
        <li>Gestion des livrables et de la recette</li>
        <li>Suivi des projets</li>
        <li>Méthodes agiles</li>
        <li>Qualité du livrable</li>
        <li>Estimation des coûts</li>
        <li>Gestion des risques</li>
        </ul></li>
        <li><b>Sciences humaines et sociales</b><ul>
        <li>Anglais</li>
        <li>Communication écrite et orale</li>
        <li>Ecoute client</li>
        <li>Conduite et comptes rendus de réunion</li>
        <li>Veille technologique</li>
        </ul></li>
        <li><b>Mises en situation (planification et suivi du projet, développement, déploiement, tests, travail en
            équipe)</b>
        </li>
        <li><b>Missions en entreprise (soutenance)</b></li>
    </ul>
    <b>Compétences visées</b><br/>
    Notre Expert accompagne l’entreprise dans la mise en place de différentes solutions informatiques et apporte une
    véritable dimension de conseil en support des solutions techniques.
    <ul>
        <li>Analyser et reformuler le besoin du client</li>
        <li>Rédiger les spécifications fonctionnelles et techniques</li>
        <li>Élaborer le cahier des charges</li>
        <li>Estimer les coûts et les délais</li>
        <li>Analyser les risques liés à la réalisation du projet</li>
        <li>Décomposer le projet en livrables et tâches</li>
        <li>Planifier un projet</li>
        <li>Déterminer les ratios d’activités et de performances</li>
        <li>Intégrer un référentiel de bonnes pratiques dans la gestion de projet</li>
        <li>Collaborer à la réalisation du projet et à son suivi dans le respect des lignes de conduite</li>
        <li>Assurer un reporting de ses activités.</li>
        <li>Développer des applications d’entreprise</li>
        <li>Développer des jeux vidéos</li>
        <li>Développer des applications web et mobiles</li>
        <li>Gérer des systèmes et des bases de données</li>
    </ul>

    <b>Objectifs</b><br/>
    Le titulaire de la certification doit pouvoir travailler dans les domaines informatiques suivants : développer des
    applications, gérer des systèmes et réseaux, administrer des bases de données, appliquer des méthodes de conception
    de logiciels, gérer des projets.<br/>

    Il exerce de nombreuses fonctions, responsable de gestion de configuration, responsable d’intégration, développeur
    d’applications, études et développement informatiques.<br/>

    Il travaille en équipe projet et donc maîtrise les méthodes de travail en équipe. Ses missions s’organisent autour
    d’activités permettant de réaliser aussi bien de gros logiciels industriels que de développement d’application de
    moindre dimension.<br/>
</>;

export function setCtaModal(id,setShow,setDataModal){
    if(id===1){
        setDataModal({title:prog1title,body:prog1body})
        setShow(true)
    }
    if (id===2){
        setDataModal({title:prog2title,body:prog2body})
        setShow(true)
    }
}
export function Cta(content,setShow,setDataModal){
    return (<>
        <section id="call-to-action" className="call-to-action">
            <div className="container text-center" data-aos="zoom-out">
                <h3>{content.ctaTitle}</h3>
                <p>{content.ctaSubtitle}</p>
                <Button onClick={() => setCtaModal(1,setShow,setDataModal)} className="cta-btn">{content.ctaBtn1}</Button>
                <Button onClick={() => setCtaModal(2,setShow,setDataModal)} className="cta-btn">{content.ctaBtn2}</Button>
            </div>
        </section>
    </>);
}
