
import '../assets/style/stat.css'
export function Stat(content,rows){

    return(
        <section id="stats" className="stats">

            <div className="container">

                <header className="section-header">
                    <h2>{content.statTitle}</h2>
                    <p>{content.statSubtitle}</p>
                </header>

                <div className="row gy-4 d-flex justify-content-center">
                    {rows.map(row=>{
                        return(
                            <div key={row.id} className="col-lg-4 col-md-6">
                                <div className={row.statDivClassname}>
                                    {/*{row.statIcon}*/}
                                    <h3>{row.statTitle}</h3>
                                    {row.statSubtitle}
                                </div>
                            </div>
                        )})}



                </div>

            </div>

        </section>
    )

}