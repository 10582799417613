import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import {UserContext,contextPrototype} from "./service/userContextService";

// import Signout from "./components/signout";
// import Login from "./components/login";
// import Admin from "./components/admin";
// import ContentConf from "./pages/contentconf";
// import ContentForm from "./pages/contentconf";

function App() {
  return (
      <UserContext.Provider value={contextPrototype}>
      <main>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </main>
      </UserContext.Provider>
  );
}

export default App;
