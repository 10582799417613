import React from "react";
// import {insecureGet} from "../service/getDataService";
import '../assets/style/about.css'

// export function AboutForm(about,setAbout,aboutRow,setAboutRow){
//     let length=0
//     const handleChangeAbout=(event=>{
//
//             // const key = event.target.name
//             // const value = event.target.value
//             // setAbout({ ...about, [key]: value })
//     })
//     const handleChangeAboutRow=(event=>{
//         let id=event.target.name.split("-")[1]
//         let varia=event.target.name.split("-")[2]
//         let value = event.target.value
//         const newRow=aboutRow.map((row,index)=>{
//             console.log(typeof (id))
//             console.log(typeof (index.toString()))
//             console.log(index.toString()===id)
//             if(index.toString()===id){
//                 console.log(row.hasOwnProperty(varia))
//                 row[varia]=value
//                 return row
//             }
//             else{
//                 return row
//             }
//         })
//         console.log(newRow)
//         setAboutRow(newRow)
//         // const key = event.target.name
//         // const value = event.target.value
//         // setAboutRow({ ...aboutRow, [key]: value })
//     })
//     const addRow=()=>{}
//     return(
//         <>
//             <FormGroup>
//                 <FormLabel>Titre</FormLabel>
//                 <FormControl type="text" name="title" value={about.title} onChange={handleChangeAbout} required/>
//             </FormGroup>
//             <FormGroup>
//                 <FormLabel>couleur texte</FormLabel>
//                 <FormControl type="text" name="colortest" value={about.colortest} onChange={handleChangeAbout} required/>
//             </FormGroup>
//             {
//                 aboutRow.map((row,index)=>{
//                     let name="Paragraphe "+(index+1)
//                     return(
//                         <FormGroup key={index}>
//                             <FormLabel>{name}</FormLabel>
//                             <FormControl type="text" name={"about-"+index+"-content"} value={row.content} onChange={handleChangeAboutRow} required/>
//                             <FormLabel>{name}</FormLabel>
//                             <FormControl type="text" name={"about-"+index+"-content1"} value={row.content1} onChange={handleChangeAboutRow} required/>
//                             <FormLabel>{name}</FormLabel>
//                             <FormControl type="text" name={"about-"+index+"-content2"} value={row.content2} onChange={handleChangeAboutRow} required/>
//                         </FormGroup>
//                     )
//                 })
//             }
//             <FormGroup>
//                 <Button onClick={addRow}>+</Button>
//             </FormGroup>
//         </>
// )
//
// }
export function About(content) {
    return (
        <section id="about" className="about">

            <div className="container" >
                <div className="row gx-0">

                    <div className="col-lg-6 d-flex flex-column justify-content-center" >
                        <div className="content">
                            <h3>{content.aboutTitle}</h3>
                            <h2>{content.aboutSubtitle}</h2>
                            <p>
                                {content.aboutContent}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6 d-flex align-items-center">
                        <img src="/img/about.jpg" className="img-fluid" alt=""/>
                    </div>
                </div>
            </div>

        </section>

    );
}