import {Accordion} from "react-bootstrap";
import "../assets/style/faq.css";
export default function Faq(content,rows){
    return (
        <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-4">
                        <div className="content px-xl-5">
                            <h3>{content.faqTitle}</h3>
                            <p>
                                {content.faqSubtitle}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <Accordion defaultActiveKey="0">
                            {
                                rows.map(row => {
                                    return (

                                        <Accordion.Item key={row.id} eventKey={row.id}>
                                            <Accordion.Header>{row.faqTitle}</Accordion.Header>
                                            <Accordion.Body>
                                                {row.faqContent}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
}
