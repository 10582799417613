import '../assets/style/review.css'
import {Accordion} from "react-bootstrap";
export default function Review(content,rows){

    return(
        <section id="testimonials" className="testimonials">
            <div className="container" data-aos="fade-up">
                <div className="section-header">
                    <h2>{content.reviewTitle}</h2>
                    <p>{content.reviewSubtitle}</p>
                </div>

                {/*<div className="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">*/}
                    {/*<div className="swiper-wrapper">*/}
                        <Accordion defaultActiveKey="0">
                            {
                                rows.map(row => {
                                    return (

                                        <Accordion.Item key={row.id} eventKey={row.id}>
                                            <Accordion.Header>{row.reviewName} - {row.reviewJob} - {row.reviewRate}</Accordion.Header>
                                            <Accordion.Body>
                                                {row.reviewComment}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                        {/*{rows.map(row=>{*/}
                        {/*    return(*/}
                        {/*// <div className="swiper-slide">*/}
                        {/*//     <div className="testimonial-wrap">*/}
                        {/*//         <div className="testimonial-item">*/}
                        {/*//             <div className="d-flex align-items-center">*/}
                        {/*//                 <img src={row.reviewImgSrc} className="testimonial-img flex-shrink-0" alt=""/>*/}
                        {/*//                 <div>*/}
                        {/*//                     <h3>{row.reviewName}</h3>*/}
                        {/*//                     <h4>{row.reviewJob}</h4>*/}
                        {/*//                     <div className="stars">*/}
                        {/*//                         {row.reviewRate}*/}
                        {/*//                     </div>*/}
                        {/*//                 </div>*/}
                        {/*//             </div>*/}
                        {/*//             <p>*/}
                        {/*//                 <i className="bi bi-quote quote-icon-left"></i>*/}
                        {/*//                 {row.reviewComment}*/}
                        {/*//                 <i className="bi bi-quote quote-icon-right"></i>*/}
                        {/*//             </p>*/}
                        {/*//         </div>*/}
                        {/*//     </div>*/}
                        {/*// </div>*/}
                        {/*    )})}*/}


                    {/*</div>*/}
                {/*</div>*/}

            </div>
        </section>
    )
}
