import '../assets/style/count.css'

export default function Count(rows) {

    return (<section id="counts" className="counts">
        <div className="container" data-aos="fade-up">

            <div className="row gy-4 justify-content-center">

                {rows.map(row => {
                    return (<div key={row.id} className="col-lg-3 col-md-6 d-flex align-content-center">
                            <div className="count-box ">
                                <i className={row.nbIcon} style={row.nbColor}></i>
                                <div>
                                <span data-purecounter-start={row.nbStart} data-purecounter-end={row.nbEnd}
                                      data-purecounter-duration="1" className="purecounter"/>
                                    <p>{row.nbLabel}</p>
                                </div>
                            </div>
                        </div>)
                })}

            </div>

        </div>
    </section>);
}
