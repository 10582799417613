import '../assets/style/pricing.css'
import {setCtaModal} from "./cta";
// import { Map, GoogleApiWrapper } from 'google-maps-react'

export function setPricingModal(setShow,setDataModal){
    setDataModal({title:"",body:<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2901.622582358196!2d5.432369176698664!3d43.343086171118564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9bfcd2c409ae5%3A0xf2fd56cab2ce1b45!2sEsimed!5e0!3m2!1sfr!2sfr!4v1710236730948!5m2!1sfr!2sfr" width="800" height="600" style={{border:"0",overflowX:"hidden",position:"fixed"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>})
    setShow(true)
}
export default function Pricing(content,rows,setShow,setDataModal){
    return (
        <section id="pricing" className="pricing">

            <div className="container" data-aos="fade-up">

                <header className="section-header">
                    <h2>{content.pricingTitle}</h2>
                    <p>{content.pricingSubtitle}</p>
                </header>

                <div className="row gy-4 d-flex justify-content-center" data-aos="fade-left">

                    {rows.map(row=>{
                        let link=<></>
                        if(row.pricingLinkLabel!==""){
                            link=<a href="https://maps.app.goo.gl/6cDeSaqWUMWe1jAw6" target="_blank" className="btn-buy">{row.pricingLinkLabel}</a>
                        }
                        return(
                    <div key={row.id} className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                        <div className="box">
                            <h3 style={row.pricingStyle}>{row.pricingTitle}</h3>
                            <div className="price">{row.pricingSubTitle}</div>
                            <img src={row.pricingImgSrc} className="img-fluid" alt={row.pricingImgAlt}/>
                            <ul>
                                {row.pricingList.map(list=>{
                                    return(<>
                                        <li key={list.id} className={list.listClassName}>{list.listLabel}</li>
                                        </>
                                        )
                                })}
                            </ul>
                            {link}

                        </div>
                    </div>)})}
                </div>
            </div>
        </section>
    );

}
