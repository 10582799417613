import '../assets/style/topbar.css'
export default function Topbar(content,rows){
    const mailto="mailto:"+content.contactemail
    return (
        <>
            <section id="topbar" className="topbar d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-md-between">
                    <div className="contact-info d-flex align-items-center">
                        <i className="bi bi-envelope d-flex align-items-center"><a
                            href={mailto}>{content.contactEmail}</a></i>
                        <i className="bi bi-phone d-flex align-items-center ms-4"><span>{content.contactPhone}</span></i>
                    </div>
                    <div className="social-links d-none d-md-flex align-items-center">
                        {
                            rows.map(row=>{
                                return(
                                    <a key={row.id} href={row.href} className={row.AClassName}><i className={row.IClassName}></i></a>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

        </>
    );
}