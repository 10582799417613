import React from "react";
import {insecureGet} from "../service/getDataService";
import {Image} from "react-bootstrap";
import "../assets/style/team.css"
// const picture = require("../assets/img/team/benoit.jpg")

export function TeamForm(team,setTeam){}
export function Team(content,rows,rows2){
    return (
        <section id="team" className="team">

            <div className="container" data-aos="fade-up">

                <header className="section-header">
                    <h2>{content.teamTitle}</h2>
                </header>

                <div className="row gy-4 d-flex justify-content-center">
                    {rows.map(row=>{return(
                    <div key={row.id} className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                         data-aos-delay="100">
                        <div className="member">
                            <div className="member-img">
                                <img src={row.teamImgSrc} className="img-fluid" alt={row.teamImgAlt}/>
                            </div>
                            <div className="member-info">
                                <h4>{row.teamName}</h4>
                                <span>{row.teamJob}</span>
                                <p>{row.teamBio}</p>
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
                <header className="section-header" style={{paddingTop:"60px"}}>
                    <h2>{content.teamSubtitle}</h2>
                </header>

                <div className="row gy-4 d-flex justify-content-center">
                    {rows2.map(row2=>{return(
                        <div key={row2.id} className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                             data-aos-delay="100">
                            <div className="member">
                                <div className="member-img">
                                    {/*<img src={row2.teamImgSrc} className="img-fluid" alt={row2.teamImgAlt}/>*/}
                                </div>
                                <div className="member-info">
                                    <h4>{row2.teamName}</h4>
                                    <span>{row2.teamJob}</span>
                                    <p>{row2.teamBio}</p>
                                </div>
                            </div>
                        </div>
                    )})}
                </div>
            </div>

        </section>
    );
}



//<section id="team" className="team section-bg">
//                     <div className="container">
//                         <div className="row">
//                             {
//                                 content.rows.map(row=> {
//                                     let bgimg="url(\""+row.imgurl+"\")"
//                                     return (
//                                         <div className="col-lg-6 mt-4">
//                                             <div className='row member'>
//                                             <div className=" col-lg-3 d-flex align-items-start">
//                                                 <div className="pic" style={{backgroundImage:bgimg}}>
//                                                     {/*<Image*/}
//                                                     {/*    src={row.imgurl}*/}
//                                                     {/*    className="img-fluid team-img"*/}
//                                                     {/*    alt={row.imgalt}/>*/}
//                                                 </div>
//                                             </div>
//                                                 <div className=" col-lg-8 member-info">
//                                                     <h4>{row.name}</h4>
//                                                     <span>{row.job}</span>
//                                                     <p>{row.bio}</p>
//                                                 </div>
//
//                                         </div>
//                                         </div>
//                                     )
//                                 })
//                             }
//                         </div>
//                     </div>
//                 </section>