import React from "react";
import '../assets/style/footer.css'
import {Image} from "react-bootstrap";

export function FooterForm(footer,setFooter){
}
export  function Footer(content,socialLinks){


    return (
        <footer id="footer" className="footer">

            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-5 col-md-12 footer-info">
                        <a href="/" className="logo d-flex align-items-center">
                            <span>{content.footerTitle}</span>
                        </a>
                        <p>{content.footerSubtitle}</p>
                        <div className="social-links d-flex mt-4">
                            {
                                socialLinks.map(soc=>{
                                    return(
                                        <a href={soc.href} key={soc.id} className={soc.AClassName}><i className={soc.IClassName}></i></a>
                                    )})
                            }
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <a href="https://extranet.esimed.fr/CERTIFICAT-ESIMED-2024-2027.pdf" target="_blank" rel="noreferrer" ><Image src={content.qualiopi} style={{width:"100%"}} alt="QualiopiLogo"/></a>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">

                    </div>

                    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                        <h4>{content.footerCol3Title}</h4>
                        <p>
                            {content.contactAdress} <br/><br/>
                            <strong>{content.contactPhoneTitle}</strong>{content.contactPhone}<br/>
                            <strong>{content.contactEmailTitle}</strong>{content.contactEmail}<br/>
                        </p>

                    </div>

                </div>
            </div>

            <div className="container mt-4">
                <div className="copyright">
                    <div className="updateDate">{content.updateDate}</div><br/>
                    <a href="https://storyset.com/people" style={{color:"#ffffff"}}>People illustrations by Storyset</a><br/>
                    &copy; Copyright <strong><span>Esimed</span></strong>. All Rights Reserved
                </div>
            </div>

        </footer>
    )
}
