import React, {useState} from "react";
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {ModalBody, ModalFooter} from "react-bootstrap";
export default function ModalTemplate(content,show,setShow){
    // const[show,setShow]=useState(false)
    // const [mC,setMC]=useState("")
    // const handleClose=()=>{
    //     setShow(false)
    // }
    // const handleShow=(param)=>{
    //     setMC(param)
    //     setShow(true)
    //     return(<></>)
    // }

    return(
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{content.title}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                {content.body}
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    )
}