import React, {useEffect, useState} from "react";
import PureCounter from "@srexi/purecounterjs";
import {Footer} from "../components/footer";
import {Contact} from "../components/contact";
import {Team} from "../components/team";
import {About} from "../components/about";
import {Cta} from "../components/cta";
import Header from "../components/header";
import Topbar from "../components/topbar";
import {Hero} from "../components/hero";
import Faq from "../components/faq";
import Pricing from "../components/pricing";
import Review from "../components/review";
import Service from "../components/service";
import Count from "../components/count";
import ModalTemplate from "../components/modaltemplate";
import {Stat} from "../components/stat"
import {Target} from "../components/target";
import {Handi} from "../components/handi";
import {Perf} from "../components/perf";



export default function Home(){
    useEffect(() => {
        let pure;
        pure = new PureCounter();
    }, []);

    const content={
        heroTitle:<img src="/img/logo.png" className="d-inline-block align-top" alt="" width="100%"/>,
        heroSubtitle:"ÉCOLE SUPÉRIEURE PRIVÉE D'INFORMATIQUE EN ALTERNANCE",
        heroBtnHref:"https://extranet.esimed.fr/inscription/",
        heroBtnContent:"Inscription en ligne ",
        heroImgSrc:"/img/bg-hero.svg",
        heroImgAlt:"",
        headerBrand: <img src="/img/Logo_esimed_maison_st_sc.png" alt="" className="d-inline-block align-top" height="30"/>,
        targetTitle:"Diplômes Proposés",
        targetSubtitle:"Un parcours en deux validations",
        aboutTitle:"L'École",
        aboutSubtitle:"ÉCOLE SUPÉRIEURE D'INFORMATIQUE EN ALTERNANCE",
        aboutBtnLabel:"En lire plus",
        aboutContent:<>L’esimed est le fruit de trente années de travail depuis la création de l’Institut International de Robotique et d’Intelligence Artificielle de Marseille. De 1984 à 1989, l’école constituait le département formation de l’IIRIAM qui s’adressait à des ingénieurs souhaitant se spécialiser en robotique et en intelligence artificielle. Depuis 1989, le cycle de conception et de maintenance de logiciels est mis en place.<br/><br/> Actuellement, esimed forme des ingénieurs opérationnels dans de multiples domaines de l’informatique : bases de données, technologies nouvelles, systèmes et réseaux, informatique mobile ; et de gestion, de management, de direction de projet et d’équipe pour atteindre le haut niveau exigé par l’expertise en ingénierie informatique. <br/> Depuis 1984, plus de 1200 ingénieurs ont été formés en alternance entre école et entreprise.</>,
        ctaTitle:"Programme de la Formation",
        ctaSubtitle:<>L’esimed vous propose de préparer en alternance les diplômes certifiés et homologués par l’état et le Ministère du Travail, de l’emploi et de l’insertion.<br/><br/>L’alternance à l’esimed c’est 2/3 du temps en entreprise et seulement 1/3 à l’école, avec des périodes allant jusqu’à 5 mois en entreprise. Le rythme de l’alternance c’est quatre passages de trois à quatre semaines par an à l’école suivis de passages de six à vingt-quatre semaines en entreprise.</>,
        ctaHref1:"/",
        ctaBtn1:"Programme du Bachelor Développement Web et Mobile BAC+3",
        ctaHref2:"/",
        ctaBtn2:"Programme de la Certification Expert en Développement Logiciel BAC+5",
        serviceTitle:"Compétences",
        serviceSubtitle:"Une série de compétences à portée de main",
        statTitle:"Statistiques",
        statSubtitle:"",
        reviewTitle:"Témoignages",
        reviewSubtitle:"Certains retours de nos étudiants ou de leurs entreprises",
        teamTitle:"L'équipe pédagogique",
        teamSubtitle:"Complétée par des intervenants qualifiés",
        handiTitle:"Handicap et L'École",
        handiContent:<>Les locaux de l’esimed ont été pensé dans une optique d'accessibilité. Ils sont intégralement en rez-de-chaussée rendant tous les espaces accessibles aux personnes à mobilité réduite.<br/><br/>Referent handicap : Henri Michelon<ul><li>Téléphone : 06 51 57 91 73</li><li>E-Mail : <a href="mailto:contact@esimed.fr">contact@esimed.fr</a></li></ul></>,
        pricingTitle:"Accessibilité",
        pricingSubtitle:"",
        faqTitle:<>Foire aux <strong>Questions</strong></>,
        faqSubtitle:<>Quelques réponses aux questions que vous pourriez vous poser. Si votre question n'y est pas, vous pouvez nous contacter via notre Email ou via le formulaire de contact.</>,
        contactTitle:"Contact",
        contactSubtitle:"En cas de questions ou pour nous rencontrer",
        contactAdressTitle:"Adresse:",
        contactAdress:<><b>Esimed</b><br/> Technopôle de Château-Gombert<br/>Europarc Bat B<br/>22 rue John Maynard Keynes<br/> 13013 MARSEILLE France</>,
        contactEmailTitle:"Email:",
        contactEmail:"contact@esimed.fr",
        contactPhoneTitle:"Téléphone:",
        contactPhone:"+33 6 51 57 91 73",
        contactPlaceHolderName:"Votre nom",
        contactPlaceHolderResponse:"Votre Email",
        contactPlaceHolderSubject:"Objet",
        contactPlaceHolderMessage:"Message",
        contactLoadingMessage:"Chargement",
        contactSuccessMessage:"Votre message a bien été envoyé.",
        contactErrorMessage:"Une erreur est survenue",
        contactBtnLabel:"Envoyer",
        footerTitle:"Esimed",
        footerSubtitle:"ÉCOLE SUPÉRIEURE D'INFORMATIQUE EN ALTERNANCE",
        footerCol3Title:"Contact Us",
        qualiopi:'/img/qualiopi2.png',
        updateDate:"mis à jour le : 14/05/2024",
        perfTitle:"Le conseil de perfectionnement",
        perfContent:<>L’esimed fait appel à des professionnels et à des jeunes diplômés pour être force de proposition, afin de permettre l’évolution continue par, entre autres, l’évaluation des enseignements et de l’insertion professionnelle.
            <br/><br/>
            Le conseil de perfectionnement est un organe consultatif qui se réunit au moins une fois par an. Il est composé de représentants des entreprises travaillant avec l’école dans le cadre de l’alternance, d’anciens diplômés, des enseignants, des personnels administratifs et d’étudiants.
            <br/><br/>
            Le conseil de perfectionnement est aussi un organe de dialogue chargé d’éclairer l’équipe pédagogique sur la situation actuelle et prospective de l’emploi dans le champ couvert par le titre, de faciliter la communication entre l’équipe pédagogique du diplôme et le tissu économique, d’aider à mettre en place une démarche compétences et de participer à la constitution du référentiel de formation du diplôme.
            <br/><br/>
            Lors des réunions le débat porte aussi sur les résultats des évaluations et sur la nature et le niveau des enseignements suivis par les étudiants pour en tenir compte pour l’avenir et pour s’assurer de la cohérence entre le parcours de la mention et la définition des programmes.
            <br/><br/>
            Un bilan sur les résultats des enquêtes d’insertion ou de poursuites d’études est dégagé.
            <br/><br/>
            Le conseil de perfectionnement contribue au processus d’amélioration continue de la formation.
            <br/><br/>
            Le dernier compte rendu du conseil de perfectionnement est disponible sur <a href="#contact">demande</a>.
        </>
        //treize pages ou modal à ajouter
    }
    const rowsTopBar=[
        {
            id:0,
            href:"/",
            AClassName:"twitter",
            IClassName:"bi bi-twitter"
        },
        {
            id:1,
            href:"/",
            AClassName:"facebook",
            IClassName:"bi bi-facebook"
        },
        {
            id:2,
            href:"/",
            AClassName:"instagram",
            IClassName:"bi bi-instagram"
        },
        {
            id:3,
            href:"/",
            AClassName:"linkedin",
            IClassName:"bi bi-linkedin"
        }
    ]
    const rowsHeader=
        [
            {
                id:0,
                href:"#program",
                content:"Programme"
            },
            {
                id:1,
                href:"#about",
                content:"L'école"
            },

            {
                id:2,
                href:"#competence",
                content:"Compétences"
            },
            {
                id:3,
                href:"#reviews",
                content:"Avis"
            },
            {
                id:4,
                href:"#team",
                content:"Équipe"
            },
            {
                id:5,
                href:"#accessibility",
                content:"Accessibilité"
            },
            {
                id:6,
                href:"#questions",
                content:"F.A.Q"
            },
            {
                id:7,
                href:"#contact",
                content:"Contact"
            }
        ]
    const rowsHero = [
        {
            id: 0,
            IclassName: "bi bi-easel",
            content: "Un cursus en alternance"
        },
        {
            id: 1,
            IclassName: "bi bi-gem",
            content: "Une formation rémunérée"
        },
        {
            id: 2,
            IclassName: "bi bi-geo-alt",
            content: "Des métiers d'avenir"
        },
        {
            id: 3,
            IclassName: "bi bi-command",
            content: "Des programmes innovants"
        },
    ];
    const rowsTarget=[
        {
            id:0,
            targetStyle:{color: "#07d5c0"},
            targetTitle: <>
                <h3>Titre Professionnel - Concepteur développeur d'applications</h3>
                <div className="targ"><a href="https://www.francecompetences.fr/recherche/rncp/37873/#ancre4"> RNCP
                    Niveau 6</a> </div> </>,
        },
        {
            id:1,
            targetStyle:{color: "#65c600"},
            targetTitle:<>
                <h3>Titre Professionnel - Manager de solutions digitales et data</h3>
                <div className="targ"><a href="https://www.francecompetences.fr/recherche/rncp/34408/"> RNCP
                    Niveau 7</a> </div> </>,
        }
    ]
    const rowsCount = [
        {
            id:0,
            nbStart: "0",
            nbEnd: "3",
            nbLabel: "Années d'études",
            nbIcon: "bi bi-emoji-smile",
            nbColor: {color: '#4154f1'},

        },
        {
            id:1,
            nbStart: "0",
            nbEnd: "2",
            nbLabel: "Diplômes reconnus à l'échelle européenne",
            nbIcon: "bi bi-journal-richtext",
            nbColor: {color: '#ee6c20'},
        },
        {
            id:2,
            nbStart: "0",
            nbEnd: "95",
            nbLabel: "% et plus de réussite",
            nbIcon: "bi bi-headset",
            nbColor: {color: '#15be56'},
        },
        {
            id:3,
            nbStart: "0",
            nbEnd: "100",
            nbLabel: "% d'embauche à la sortie",
            nbIcon: "bi bi-people",
            nbColor: {color: '#bb0852'},
        },
        {
            id:4,
            nbStart: "0",
            nbEnd: "60",
            nbLabel: "% du temps en entreprise",
            nbIcon: "bi bi-people",
            nbColor: {color: '#bb0852'},
        },
        {
            id:5,
            nbStart: "0",
            nbEnd: "40",
            nbLabel: "% du temps en école",
            nbIcon: "bi bi-people",
            nbColor: {color: '#bb0852'},
        },
    ];
    const rowsService=[
        {
            id:0,
            serviceDivClassname:"service-box blue",
            serviceIcon:<i className="bi bi-code-slash icon"/>,
            serviceTitle:"Développement logiciel",
            serviceSubtitle:"Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.",
            serviceHref:"/",
            serviceLink:"Read More"
        },
        {
            id:1,
            serviceDivClassname:"service-box orange",
            serviceIcon:<div className="icon"><i className="bi bi-globe "/><i className="bi  bi-phone "/><i className="bi bi-controller"/></div>,
            serviceTitle:"Développement Web, Mobile et Jeux Vidéo ",
            serviceSubtitle:"Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.",
            serviceHref:"/",
            serviceLink:"Read More"
        },
        {
            id:2,
            serviceDivClassname:"service-box green",
            serviceIcon:<i className="bi bi-person-gear icon"/>,
            serviceTitle:"Ingénierie de projet",
            serviceSubtitle:"Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.",
            serviceHref:"/",
            serviceLink:"Read More"
        },
        {
            id:3,
            serviceDivClassname:"service-box red",
            serviceIcon:<i className="bi bi-signpost-2 icon"/>,
            serviceTitle:"Déploiement backend et frontend",
            serviceSubtitle:"Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.",
            serviceHref:"/",
            serviceLink:"Read More"
        },
        {
            id:4,
            serviceDivClassname:"service-box purple",
            serviceIcon:<i className="bi bi-hdd-network icon"/>,
            serviceTitle:"Systèmes et Réseaux",
            serviceSubtitle:"Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.",
            serviceHref:"/",
            serviceLink:"Read More"
        },
        {
            id:5,
            serviceDivClassname:"service-box pink",
            serviceIcon:<i className="bi bi-people-fill icon"/>,
            serviceTitle:"Sciences humaines et sociales",
            serviceSubtitle:"Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.",
            serviceHref:"/",
            serviceLink:"Read More"
        },
    ]
    const rowsStat=[
        {
            id:0,
            statDivClassname:"stat-box blue",
            statIcon:<i className="bi bi-code-slash icon"/>,
            statTitle:<>
                Taux d'obtention des diplômes ou titre professionnels sortants 2022:</>,
            statSubtitle:
                <ul>
                    <li>Expert en Système Informatique:<br/>
                        <b>94.7%</b> (19 présentés,18 reçus)
                    </li>
                    <li>Concepteur Développeur d’Application :<br/>
                        <b>94.4%</b> (18 présentés,17 reçus)
                    </li>
                </ul>
            ,
            statHref:"/",
            statLink:"Read More"
        },
        {
            id:1,
            statDivClassname:"stat-box orange",
            statIcon:<div className="icon"><i className="bi bi-globe "/><i className="bi  bi-phone "/><i className="bi bi-controller"/></div>,
            statTitle:<>
                Taux d'insertion professionnelle des sortants :
            </>,
            statSubtitle:
                <ul>
                    <li>Expert en Système Informatique :<br/>
                        <b>100%</b> (18 reçus, 18 CDI) dans le domaine d''activité
                    </li>
                </ul>,
            statHref:"/",
            statLink:"Read More"
        },
        {
            id:2,
            statDivClassname:"stat-box green",
            statIcon:<i className="bi bi-person-gear icon"/>,
            statTitle:<>
                Taux de poursuite d'études:
            </>,
            statSubtitle:<p><br/>
                <ul>
                    <li>Concepteur Développeur D’Applications :<br/> <b>100%</b> (17 reçus, 17 poursuites)
                    </li>
                </ul></p>,
            statHref:"/",
            statLink:"Read More"
        },
        {
            id:3,
            statDivClassname:"stat-box red",
            statIcon:<i className="bi bi-signpost-2 icon"/>,
            statTitle:<>
                Taux et causes des abandons :</>
            ,
            statSubtitle:<p><ul>
                <li>Expert en Système Informatique :<br/>
                    <b>5,3%</b> (1 échec aux évaluations)
                </li>
                <li>Concepteur Développeur d’Application :<br/>
                    <b>5,6%</b> (1 non présentation à l''examen)
                </li>
            </ul></p>,
            statHref:"/",
            statLink:"Read More"
        },
        {
            id:4,
            statDivClassname:"stat-box purple",
            statIcon:<i className="bi bi-hdd-network icon"/>,
            statTitle:<>
                Taux de rupture des contrats d’alternance (apprentissage et professionnalisation):
            </>,
            statSubtitle:<p><ul><li><b>1.88%</b> (1 rupture de contrat d’apprentissage)</li></ul></p>,
            statHref:"/",
            statLink:"Read More"
        },

    ]
    const rowsReview =
        [
            {
                id: 0,
                reviewName: "Romain DUCAT",
                reviewJob: "Promotion 2009-2011",
                reviewRate: <><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/></>,
                reviewComment: <><h3>Une école sérieuse</h3><br/>Issu d’un BTS Informatique de Gestion option Réseau, j’avais pour ambition de ne pas arrêter là mon cursus scolaire.
                    Devant faire face au problème récurrent des étudiants devant concilier finances et études, je me suis donc porté vers la formation en alternance.
                    Après de longues recherches, c’est toujours l’ESIMED qui est revenu dans les conseils et avis, comme une école sérieuse, de qualité, avec un passé solide de formation des étudiants.
                    Après ces deux années de formation en alternance, je dois dire que je n’ai pas été déçu de mon choix.
                    La formation fut certes intense au niveau du programme, mais extrêmement enrichissante. Chaque matière enseignée est traitée en profondeur, en détails. Toutes les nouvelles technologies sont enseignées par le biais de cours théoriques comme pratiques. Pour résumer, un enseignement de qualité !
                    L’alternance permet une véritable insertion dans le milieu professionnel. Les périodes d’un mois sur deux en entreprise, étalées sur deux ans, donnent la possibilité de se voir confier de vrais projets, avec des missions concrètes et pérennes pour l’entreprise.
                    Après deux années d’alternance en qualité de chef de projet junior, je suis maintenant Responsable Maintenance & Service au sein de « Welcome Real-Time », leader mondial sur le marché de solutions de fidélisation bancaire. Une entreprise internationale qui me permet de voyager très souvent à travers le monde, et de gérer des projets de grosse envergure, avec des clients de toutes nationalités.
                    Cette opportunité professionnelle n’aurait pas pu être possible sans avoir fait cette école, je ne peux donc que la conseiller !</>,
                reviewImgSrc: "/img/testimonials/testimonials-1.jpg"
            },
            {
                id: 1,
                reviewName: "Laurent THEVENET",
                reviewJob: "Ceo & Founder",
                reviewRate: <><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/></>,
                reviewComment: <><h3>L’esimed vous forme à des techniques qui sont adaptées à ce que le marché recherche en France ou à l’étranger</h3><br/>Après avoir quitté la France en 2008, j’ai migré vers Singapour (qui est un peu le centre stratégique en Asie/Pacifique pour de nombreuses boîtes dont de très nombreuses entreprises francaises, notamment banques, assurances, aviation, militaires et agences créatives). Sûrement plus que Hong Kong parce que Singapour est au carrefour de l’Australie, de l’Inde et de la Chine et la population est extrêmement variée. La compétitivité est extrême. C’est stressant et fatiguant mais les opportunités sont quotidiennes et réelles. Les gens ont de l’argent à dépenser et les entreprises embauchent à tour de bras.
                    Il est intéressant de noter que les étudiants de l’ECML (maintenant ESIMED donc) en plus d’avoir des ouvertures en France ont également la possibilité de s’expatrier. Le diplôme équivalent délivré par l’université (en tout cas à mon époque, et si c’est toujours le cas) est reconnu en tant que BSC (graduate) ici à Singapore et vous permet d’obtenir un permis de travail pour postuler à différents types d’emplois. L’obtention du permis de travail est également fonction de l’expérience du candidat, ce dont vos étudiants disposent immédiatement après deux ans de formation.
                    La formation francaise (et européenne en général) est bien reconnue car pour eux, on « think out of the box ». Les informaticiens locaux (pour la plupart chinois et indiens) sont doués, travaillent vite, (au niveau technique comparé à ce qui nous est enseigné à l’esimed).
                    Donc oui l’ECML (ESIMED) est une bonne formation, vous donne une expérience de travail mais vous forme également à des techniques qui sont adaptées à ce que le marché recherche en France ou à l’étranger.
                    J’ai vu sur le site que le programme avait évolué dans la bonne direction. Pour maintenant travailler (sous ma propre entreprise) dans un domaine plus créatif, HTML5, CSS3, Javascript, développement iOS et Android sont des atouts indéniables pour un jeune diplômé. Lorsque les projets sur lesquels je travaille demandent ces compétences là et que je dois déléguer, je ne trouve pas forcément des développeurs de qualité dans la région (qui ne sont pas seulement des developpeurs web qui travailleraient vite mais avec une vision superficielle mais qui ont également une compréhension plus globale des systèmes informatiques, ce que l’ECML peut nous enseigner).</>,
                reviewImgSrc: "/img/testimonials/testimonials-1.jpg"
            },
            {
                id: 2,
                reviewName: "MAUREL Vincent",
                reviewJob: "Conseiller .Net (Nurun Inc, CANADA)",
                reviewRate: <><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/><i className='bi bi-star-fill'/></>,
                reviewComment: <><h3>Une vraie expérience professionnelle en entreprise</h3>J’ai choisi l’école ESIMED au cours de ma reconversion professionnelle en 2010. Anciennement professeur de mathématiques, je cherchais une formation diplômante, à la fois théorique et pratique.
                    La formation par l’école ESIMED proposait à la fois des enseignements théoriques sur les langages de programmation les plus utilisés en entreprise aujourd’hui, et une vraie expérience professionnelle en entreprise que l’on pouvait acquérir au cours des deux années d’alternance.
                    Le rythme de l’alternance dans cette école a également été un facteur important dans ma décision d’intégrer cette école, les périodes en entreprise permettant de s’impliquer dans projets de l’entreprise, et les périodes à l’école permettant de se concentrer uniquement sur les matières étudiées.
                    J’ai décroché un contrat de professionnalisation dans la société Lememo.com suite à une candidature spontanée que j’ai adressée à cette entreprise, après un entretien avec M. Stéphane Bonhomme, chef de projet et responsable du département informatique de l’entreprise à cette époque
                    En entreprise, j’ai été très rapidement intégré à l’équipe, et les échanges avec mes collaborateurs ont été très intéressants et enrichissants. J’ai beaucoup appris des personnes avec lesquelles j’ai travaillé, mais j’ai également apporté beaucoup d’idées nouvelles, notamment à chaque retour des périodes de formation à ESIMED</>,
                reviewImgSrc: "/img/testimonials/testimonials-1.jpg"
            },
        ];
    const rowsTeam=[
        {
            id:0,
            teamImgSrc:"/img/team/AM.png",
            teamImgAlt:"",
            teamName:"Ahmed Mebkhout",
            teamJob:"Directeur",
            teamBio:""
        },
        {
            id:1,
            teamImgSrc:"/img/team/HM.png",
            teamImgAlt:"",
            teamName:"Henri Michelon",
            teamJob:"Responsable pédagogique, référent handicap et mobilité",
            teamBio:""
        },
    ]
    const rowsTeam2=[
        {
            id:0,
            teamImgSrc:"/img/team/AM.png",
            teamImgAlt:"",
            teamName:"Tony Anzante",
            teamJob:"professeur d'anglais",
            teamBio:""
        },
        {
            id:1,
            teamImgSrc:"/img/team/HM.png",
            teamImgAlt:"",
            teamName:"Michaël Cozette",
            teamJob:"ingénieur, chef d’entreprise : langages de requêtes, administration de bases de données.",
            teamBio:""
        },
        {
            id:2,
            teamImgSrc:"/img/team/RD.png",
            teamImgAlt:"",
            teamName:"Frédéric Dumas",
            teamJob:"ingénieur : C++, UML, gestion de projet.",
            teamBio:""
        },
        {
            id:3,
            teamImgSrc:"/img/team/HM.png",
            teamImgAlt:"",
            teamName:"Pascal Montoya",
            teamJob:"ingénieur : administration de bases de données MySQL, PHP, framework Symfony.",
            teamBio:""
        },
        {
            id:4,
            teamImgSrc:"/img/team/RD.png",
            teamImgAlt:"",
            teamName:"Yves Thomas",
            teamJob:"animateur association, maître de conférence : techniques de recherche d’emploi.",
            teamBio:""
        }
    ]
    const rowsPricing=[
        {
            id:0,
            pricingStyle:{color: "#07d5c0"},
            pricingTitle:<>Un accès pratique </>,
            pricingSubTitle:<>En transport en commun ou individuel</>,
            pricingImgSrc:"/img/pricing-business.png",
            pricingImgAlt:"",
            pricingList:[
                {
                    id:0,
                    listLabel:"Métro ligne n°1 LA ROSE-LA FOURRAGERE, arrêt terminus La Rose.",
                    listClassName:""
                },
                {
                    id:1,
                    listLabel:"Bus B3B Votre point d’arrêt : TECHNOPOLE CENTRALE MARSEILLE",
                    listClassName:""
                },
                {
                    id:2,
                    listLabel:"Depuis autoroute EST A50, suivre Technopôle de Château Gombert.",
                    listClassName:""
                },
                {
                    id:3,
                    listLabel:"Depuis autoroute NORD A7, suivre Technopôle Château Gombert.",
                    listClassName:""
                },
                {
                    id:4,
                    listLabel:"Itinéraire vers le 22 rue John Maynard Keynes, 13013 Marseille",
                    listClassName:""
                },
            ],
            pricingLinkHref:"https://maps.app.goo.gl/6cDeSaqWUMWe1jAw6",
            pricingLinkLabel:"Voir la carte"
        },
        {
            id:1,
            pricingStyle:{color: "#65c600"},
            pricingTitle:"Aide au placement en entreprise",
            pricingSubTitle:"Accompagnement dans toutes vos démarches de recherche d’entreprise.",
            pricingImgSrc:"/img/pricing-free.png",
            pricingImgAlt:"",
            pricingList:[

                {
                    id:0,
                    listLabel:"Dès la réception de votre inscription et de votre CV, celui-ci est retravaillé pour être présenté aux entreprises partenaires.",
                    listClassName:""
                },{
                    id:1,
                    listLabel:"Toutes les démarches administratives, depuis l’inscription jusqu’à la signature du contrat avec l’entreprise d’accueil sont prises en charge par les services de l’école.",
                    listClassName:""
                },

                {
                    id:2,
                    listLabel:"Chaque candidat est suivi personnellement jusqu’à son inscription définitive.",
                    listClassName:""
                },
            ],
            pricingLinkHref:"#",
            pricingLinkLabel:""
        },
        {
            id:2,
            pricingStyle:{color: "#ff901c"},
            pricingTitle:"Hébergement",
            pricingSubTitle:"Accès au CROUS local et aux cités étudiantes autour de l'école",
            pricingImgSrc:"/img/pricing-starter.png",
            pricingImgAlt:"",
            pricingList:[
                {
                    id:0,
                    listLabel:"Cité Chatenoud 13ème",
                    listClassName:""
                },
                {
                    id:1,
                    listLabel:"Cité Claude Delorme",
                    listClassName:""
                },
                {
                    id:2,
                    listLabel:"Les Estudines de Provence",
                    listClassName:""
                },
                {
                    id:3,
                    listLabel:"Les Estudines Victoria Park",
                    listClassName:""
                },
                {
                    id:4,
                    listLabel:"Les estudines Oxford",
                    listClassName:""
                },
            ],
            pricingLinkHref:"#",
            pricingLinkLabel:""
        }
    ]
    const rowsFaq=[
        {
            id:0,
            faqTitle:"Les locaux sont-ils accessibles aux personnes à mobilité réduite? ",
            faqContent:"Les locaux de l’esimed sont en rez-de-chaussée. Tous les espaces sont accessibles pour tout le monde y compris aux personnes handicapées.\n" +
                "\n" +
                "Référent handicap : Henri Michelon – contact."
        },
        {
            id:1,
            faqTitle:"Puis-je avoir une entreprise éloignée de Marseille?",
            faqContent:"Grâce au rythme de l'aternance à l'Esimed et ses quatre passages de trois à quatre semaines par an à l’école suivis de passages de six à vingt quatre semaines en entreprise ainsi qu'à nos partenariat avec le CROUS, vous pouvez profiter de cette modularité pour choisir votre entreprise n'importe où. "
        },
        {
            id:2,
            faqTitle:"Le diplôme est-il accessible à tous?",
            faqContent:"Notre formation est accessible à toute personne possédant un BAC +2 dans le domaine de l'informatique."
        }
    ]
    const rowSector=[
        {
           sectorTitle:"Grands comptes informatiques"
        },
        {
            sectorTitle:"Sociétés de services en ingénierie informatique"
        },
        {
            sectorTitle:"Société de conseils en informatique"
        },
        {
            sectorTitle:"Éditeurs de logiciels"
        },
        {
            sectorTitle:"Opérateurs en télécommunications"
        },
        {
            sectorTitle:"DataCenter, infogérance et hébergement"
        },
        {
            sectorTitle:"Banques, assurances"
        }
    ]
    const rowJob=[
        {
            jobTitle:"Architecte logiciel"
        },
        {
            jobTitle:"Chef de projet"
        },
        {
            jobTitle:"Développeur full stack"
        },
        {
            jobTitle:"Développeur d’applications web"
        },
        {
            jobTitle:"Développeur d’applications mobiles"
        },
        {
            jobTitle:"Développeur d’applications de gestion"
        },
        {
            jobTitle:"Développeur d’applications distribuées"
        },
        {
            jobTitle:"Administrateur système et réseau d’entreprise"
        },
        {
            jobTitle:"Administrateur de bases de données"
        },
        {
            jobTitle:"Ingénieur d’applications informatiques"
        },
        {
            jobTitle:"Consultant en systèmes d’information"
        },
        {
            jobTitle:"Ingénieur d’étude"
        },
        {
            jobTitle:"Urbaniste de SI"
        },
        {
            jobTitle:"Manager Data"
        },
        {
            jobTitle:"Acheteur IT"
        },
        {
            jobTitle:"Responsable de la transformation numérique"
        }
    ]
    // const [showModal,setShowModal]=useState(false)
    // // const[mC,setMC]=useState()
    // const handleCloseModal=()=>{}
    // // const handleShowModal=()=>setShowModal(true)
    // const modalContent=(param)=>{
    //     if (param==="12"){
    //         // setMC(content.aboutTitle)
    //         // handleShowModal()
    //         // param=""
    //         setShowModal(true)
    //     }
    //     // handleShowModal()
    // }


    const[show,setShow]=useState(false)


    const[dataModal,setDataModal]=useState({title:"",body:""})
    return (<>


            {Topbar(content, rowsTopBar)}
            {Header(content, rowsHeader,setShow,setDataModal)}
            {Hero(content, rowsHero,setShow,setDataModal)}

            <main id="main">
                <div id="program"/>
                {/*Target(content,rowsTarget,rowSector,rowJob,setShow,setDataModal)*/}
                {Cta(content,setShow,setDataModal)}
                {Count(rowsCount)}
                {Stat(content,rowsStat)}
                {About(content,setShow,setDataModal)}
                <div id="competence"/>
                {Service(content, rowsService)}
                <div id="reviews"/>
                {Review(content, rowsReview)}
                <div id="team"/>
                {Team(content, rowsTeam,rowsTeam2)}
                <div id="accessibility"/>
                {Pricing(content, rowsPricing,setShow,setDataModal)}
                {Handi(content)}
                <div id="questions"/>
                {Faq(content, rowsFaq)}
                {Perf(content)}
                <div id="contact"/>
                {Contact(content)}
                {ModalTemplate(dataModal,show,setShow)}
            </main>
            {Footer(content,rowsTopBar)}
        </>
    );
}
