import React from "react";
// import {insecureGet} from "../service/getDataService";
// import {Button, Form, FormControl, FormGroup, FormLabel, Image} from "react-bootstrap";
import 'boxicons'
// import {Link} from "react-router-dom";
import '../assets/style/contact.css'

export function ContactForm(Contact,setContact){}
export  function Contact(content) {
    // const [validated, setValidated] = useState(false);

    // const handleSubmit = (event) => {
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //     }
    //
    //     setValidated(true);
    // };
        return (
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                        <h2>{content.contactTitle}</h2>
                        <p>{content.contactSubtitle}</p>
                    </div>

                    <div className="row gx-lg-0 gy-4 d-flex align-items-center justify-content-center">

                        <div className="col-lg-4">

                            <div className="info-container d-flex flex-column align-items-center justify-content-center">
                                <div className="info-item d-flex">
                                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                                    <div>
                                        <h4>{content.contactAdressTitle}</h4>
                                        <p>{content.contactAdress}</p>
                                    </div>
                                </div>

                                <div className="info-item d-flex">
                                    <i className="bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h4>{content.contactEmailTitle}</h4>
                                        <p>{content.contactEmail}</p>
                                    </div>
                                </div>

                                <div className="info-item d-flex">
                                    <i className="bi bi-phone flex-shrink-0"></i>
                                    <div>
                                        <h4>{content.contactPhoneTitle}</h4>
                                        <p>{content.contactPhone}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    {/*    <div className="col-lg-8">*/}
                    {/*        <form action="forms/contact.php" method="post" role="form" className="php-email-form">*/}
                    {/*            <div className="row">*/}
                    {/*                <div className="col-md-6 form-group">*/}
                    {/*                    <input type="text" name="name" className="form-control" id="name" placeholder={content.contactPlaceHolderName} required/>*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-6 form-group mt-3 mt-md-0">*/}
                    {/*                    <input type="email" className="form-control" name="email" id="email" placeholder={content.contactPlaceHolderResponse} required/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="form-group mt-3">*/}
                    {/*                <input type="text" className="form-control" name="subject" id="subject" placeholder={content.contactPlaceHolderSubject} required/>*/}
                    {/*            </div>*/}
                    {/*            <div className="form-group mt-3">*/}
                    {/*                <textarea className="form-control" name="message" rows="7" placeholder={content.contactPlaceHolderMessage} required></textarea>*/}
                    {/*            </div>*/}
                    {/*            <div className="my-3">*/}
                    {/*                <div className="loading">{content.contactLoadingMessage}</div>*/}
                    {/*                <div className="error-message">{content.contactErrorMessage}</div>*/}
                    {/*                <div className="sent-message">{content.contactSuccessMessage}</div>*/}
                    {/*            </div>*/}
                    {/*            <div className="text-center"><button type="submit">{content.contactBtnLabel}</button></div>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}

                    </div>

                </div>
            </section>
        )
}

