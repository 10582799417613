import React from "react";
import "../assets/style/perf.css"
export function Perf(content) {
    return (
        <section id="perf" className="perf">
            <header className="section-header">
                <h2>{content.perfTitle}</h2>
            </header>
            <div className="container">
                <div className="row gx-0 justify-content-center">
                    <div className="col-lg-8 d-flex flex-column justify-content-center">
                        <div className="content">
                            <p>
                                {content.perfContent}
                            </p>
                        </div>
                    </div>


                </div>
            </div>

        </section>

    );
}